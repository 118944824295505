import React, { useLayoutEffect, useRef, useState } from "react";
import { Line } from "./line";
import { DeepItems } from "./deepItems";
import { InputNumber } from "./inputNumber";
import {
  Button,
  Form,
  List,
  Modal,
  notification,
  Popconfirm,
  Typography,
} from "antd";
import { RadarChartOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setQualityIndicatorsParams } from "../../redux/modelsSlice";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarRadiusAxis,
  Tooltip,
  PolarAngleAxis,
  Text,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { SavePNGButton } from "../SavePNGButton";
import styles from "./diagramNew.module.css";

const cardWidth = 200;
const step = cardWidth / 2;

function customTickBar({ payload, x, y, textAnchor, radius, height }) {
  return (
    <g>
      <Text
        x={x}
        y={y + height}
        radius={radius}
        stroke="#000"
        width="320"
        textAnchor={textAnchor}
      >
        {payload.value}
      </Text>
    </g>
  );
}

function customTick({ payload, x, y, textAnchor, radius }) {
  return (
    <g>
      <Text
        x={x}
        y={y}
        radius={radius}
        stroke="#000"
        width="120"
        textAnchor={textAnchor}
      >
        {payload.value}
      </Text>
    </g>
  );
}

export const DiagramNew = ({ title, list, indicators_params }) => {
  const [startPoint, setStartPoint] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const svgBlock = useRef();
  const [form] = Form.useForm();
  const ref = useRef();
  const [modal, setModal] = useState({
    isOpen: false,
    name: null,
    children: null,
  });

  const rightPoint = (list.length - 1) * step;

  useLayoutEffect(() => {
    if (svgBlock.current) {
      setStartPoint(svgBlock.current.clientWidth / 2);
    }
  }, [list.length]);

  const handleSubmit = async (val) => {
    const formatValues = Object.keys(val).reduce((acc, key) => {
      if (val[key] || val[key] === 0) {
        return {
          ...acc,
          [key]: Number(val[key]),
        };
      }
      return acc;
    }, {});

    const dis = await dispatch(
      setQualityIndicatorsParams({ id, formatValues })
    ).unwrap();

    if (dis.status === 200) {
      notification.success({
        message: "Успешно",
        description: "Данные успешно сохранены",
        top: 100,
        maxCount: 3,
      });
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        form={form}
        initialValues={indicators_params}
        onFinishFailed={(er) => console.log(er)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: 10,
          }}
        >
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
          <Popconfirm title="Вы уверены?" onConfirm={handleReset}>
            <Button>Отмена</Button>
          </Popconfirm>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ border: "1px solid black", padding: "2px 15px" }}>
              {title}
            </div>
          </div>
          <svg ref={svgBlock} height="40" width="100%">
            {list.map((item, index) => (
              <polyline
                key={item.id}
                points={`${startPoint} 0,${startPoint} 0, ${startPoint} 20, ${
                  startPoint + (-rightPoint + index * cardWidth)
                } 20, ${startPoint + (-rightPoint + index * cardWidth)} 40`}
                style={{ fill: "transparent", stroke: "black", strokeWidth: 2 }}
              />
            ))}
          </svg>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {list.map((item) => (
              <div
                key={item.id}
                style={{
                  width: cardWidth,
                  padding: "0px 2px",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    padding: "2px 15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {item.title}
                  {!item.children?.some(
                    (itemInner) => itemInner?.children?.length
                  ) && (
                    <Button
                      icon={<RadarChartOutlined />}
                      onClick={() =>
                        setModal({
                          name: item.title,
                          children: item.children,
                          isOpen: true,
                        })
                      }
                    />
                  )}
                </div>
                <div style={{ paddingLeft: "15px" }}>
                  {item.children?.map((itemInner, indexInner) => (
                    <div key={itemInner.id}>
                      <div style={{ display: "flex", position: "relative" }}>
                        <Line
                          index={indexInner}
                          lastIndex={indexInner === item.children.length - 1}
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingTop: indexInner === 0 ? "8px" : 0,
                            paddingLeft: "16px",
                            paddingBottom: itemInner.children?.length
                              ? 0
                              : "5px",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          {itemInner.title}
                          {!!itemInner.children?.length && (
                            <Button
                              icon={<RadarChartOutlined />}
                              onClick={() =>
                                setModal({
                                  name: itemInner.title,
                                  children: itemInner.children,
                                  isOpen: true,
                                })
                              }
                            />
                          )}
                          {(!itemInner.children ||
                            itemInner.children?.length === 0) && (
                            <InputNumber
                              size="small"
                              name={itemInner.id}
                              style={{ width: "35px", minWidth: "35px" }}
                            />
                          )}
                        </div>
                      </div>
                      <DeepItems
                        currentIndex={indexInner}
                        currentList={item.children}
                        deepList={itemInner.children}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Form>
      <Modal
        width={800}
        open={modal.isOpen}
        onCancel={() => setModal({ children: null, name: null, isOpen: false })}
        footer={null}
      >
        <div ref={ref}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "25px",
            }}
          >
            <Typography.Title level={4}>{modal.name}</Typography.Title>
            <SavePNGButton
              containerRef={ref}
              id="saveButton"
              name={modal.name}
            />
          </div>
          {!!modal.children?.length ? (
            modal.children?.length === 2 ? (
              <BarChart
                cx="50%"
                cy="50%"
                width={752}
                height={500}
                margin={{
                  bottom: 20,
                }}
                data={modal.children?.map((item) => ({
                  ...item,
                  value:
                    form.getFieldValue(item.id) ||
                    Number(indicators_params[item.id]) ||
                    0,
                }))}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis
                  dataKey="title"
                  stroke="#000"
                  interval={0}
                  tick={customTickBar}
                />
                <YAxis
                  stroke="#000"
                  tickCount={10}
                  tickSize={1}
                  ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  domain={[0, 10]}
                />
                <Tooltip
                  content={(_) => (
                    <div
                      style={{
                        padding: "10px 7px",
                        backgroundColor: "#fff",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "1px 1px 5px  #000",
                      }}
                    >
                      <Typography.Text strong>
                        {_.payload?.[0]?.payload.title}
                      </Typography.Text>
                      <Typography.Text>
                        Значение -{" "}
                        {_.payload?.[0]?.payload.id
                          ? form.getFieldValue(_.payload?.[0]?.payload.id) ||
                            indicators_params[_.payload?.[0]?.payload.id] ||
                            0
                          : 0}
                      </Typography.Text>
                    </div>
                  )}
                />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            ) : (
              <RadarChart
                cx="50%"
                cy="50%"
                width={752}
                height={800}
                className={styles.wrapper}
                data={modal.children?.map((item) => ({
                  ...item,
                  value:
                    form.getFieldValue(item.id) ||
                    Number(indicators_params[item.id]) ||
                    0,
                }))}
              >
                <PolarGrid stroke="#000" gridType="polygon" />
                <PolarRadiusAxis
                  angle={90}
                  stroke="#000"
                  tickCount={10}
                  tickSize={1}
                  ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  domain={[0, 10]}
                />
                <Radar
                  dataKey="value"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />

                <PolarAngleAxis dataKey="title" tick={customTick} />
                <Tooltip
                  content={(_) => (
                    <div
                      style={{
                        padding: "10px 7px",
                        backgroundColor: "#fff",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "1px 1px 5px  #000",
                      }}
                    >
                      <Typography.Text strong>
                        {_.payload?.[0]?.payload.title}
                      </Typography.Text>
                      <Typography.Text>
                        Значение -{" "}
                        {_.payload?.[0]?.payload.id
                          ? form.getFieldValue(_.payload?.[0]?.payload.id) ||
                            indicators_params[_.payload?.[0]?.payload.id] ||
                            0
                          : 0}
                      </Typography.Text>
                    </div>
                  )}
                />
              </RadarChart>
            )
          ) : null}
          <List
            size="small"
            bordered
            dataSource={modal.children}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text strong>{item.title}</Typography.Text>
                <Typography.Text>
                  {item.id
                    ? form.getFieldValue(item.id) ||
                      indicators_params[item.id] ||
                      0
                    : 0}
                </Typography.Text>
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </>
  );
};
