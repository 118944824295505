import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { NAV_LINKS, PROJECT_NAME } from "../../constants/project";
import { logout } from "../../redux/authSlice";
import router from "../../utils/router";
import { UserRoles } from "../../utils/roles";

const LayoutHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const isCurrentLink = (x) => (x.href === location.pathname ? x.id : null);
  const isAllowInstruction =
    (user.role === UserRoles.superuser) | (user.role === UserRoles.observer);

  const [activeKey, setActiveKey] = useState(
    NAV_LINKS.reduce(
      (prev, link) =>
        prev ||
        (link.items
          ? link.items.reduce((prev, x) => prev || isCurrentLink(x), null)
          : isCurrentLink(link)),
      null
    )
  );

  const handleLogout = () => {
    dispatch(logout());
    navigate(router.login);
  };

  const goToAdmin = (e) => {
    e.preventDefault();
    window.location.href = router.admin;
  };

  return (
    <header>
      <Navbar bg="dark" variant="dark" fixed="top" style={{ zIndex: "500" }}>
        <Container className="gap-3">
          <Navbar.Brand as={Link} to="/">
            {PROJECT_NAME}
          </Navbar.Brand>
          <Nav
            className="me-auto gap-1"
            variant="pills"
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
          >
            {NAV_LINKS.map((item, idx) =>
              item.items && item.role.includes(user.role) ? (
                <NavDropdown key={idx} title={item.name}>
                  {item.items.map((item, idx) =>
                    item === "-" ? (
                      <NavDropdown.Divider key={idx} />
                    ) : item.newLink ? (
                      <NavDropdown.Item
                        key={idx}
                        as={Link}
                        to={item.href}
                        onClick={goToAdmin}
                      >
                        {item.name}
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item
                        key={idx}
                        as={Link}
                        to={item.href}
                        eventKey={item.id}
                      >
                        {item.name}
                      </NavDropdown.Item>
                    )
                  )}
                </NavDropdown>
              ) : (
                item.role.includes(user.role) && (
                  <Nav.Item key={idx}>
                    <Nav.Link as={Link} to={item.href} eventKey={item.id}>
                      {item.name}
                    </Nav.Link>
                  </Nav.Item>
                )
              )
            )}
          </Nav>
          <div className="ms-auto">
            {isAllowInstruction && (
              <a
                target="_blank"
                className="linkHelp"
                rel="noreferrer"
                href="https://docs.google.com/presentation/d/1JysVgwULocgfDPba0uHsrdM7GVUXBrwwezrld7ll5-w/edit?usp=sharing"
              >
                Инструкция
              </a>
            )}
            <Button variant="danger" onClick={handleLogout}>
              Выйти
            </Button>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default LayoutHeader;
